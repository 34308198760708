import React, {FC} from 'react'
import styles from './content.module.css'
import help from '../../source/help.png'
import pet from '../../source/pet.png'
import basket from '../../source/basket.png'
import b5 from '../../source/bb5upd.png'

const Content: FC = () => {
    return (
        <div className={styles.contentBlock}>
            <div className={styles.obzac}>
                <img src={help} alt="?" className={styles.images}/>
                <div className={styles.obzacText}>
                Что такое <p className={styles.greenContent}> СветЗооФор</p>? Это постоянная экономия здесь и сейчас, без уловок и условностей.
                </div>
            </div>

            <div className={styles.obzac}>
                <img src={pet} alt="?" className={styles.images}/>
                <div className={styles.obzacText}>
                Забота о ваших питомцах и ваших кошельках наша первоочередная задача. Мы предлагаем <p className={styles.greenContent}>низкие цены</p>, для того чтобы у людей стало больше возможностей кормить своего питомца более качественными кормами и лакомствами. Ведь все мы знаем, что здоровье питомца зависит от правильного выбора продуктов для животных. 
                </div>
            </div>

            <div className={styles.obzac}>
                <img src={basket} alt="?" className={styles.images}/>
                <div className={styles.obzacText}>
                Каждый покупатель дискаунтера становится участником клуба светзоофор, который дает возможность на приобретение всех зоотоваров по специальной цене <p className={styles.greenContent}>-15% от цены указанной на ценнике</p>. Правила простые: пришел, завел клубную карту, сэкономил!
                </div>
            </div>
            <p className={styles.ylInfo}>
                Полное наименование организации  Общество с ограниченной ответственностью «ПЕТСТОР»
                Сокращённое наименование организации  ООО «ПЕТСТОР»
                ОГРН  1193850014434
                Регистрационные данные ОГРН: Дата и орган регистрации юридического лица: 17.05.2019 г. Межрайонная ИФНС России № 17 по Иркутской области.
                ИНН  3810080031
                ОКТМО  25701000001
                ОКФС  16
                ОКОПФ  12300
                ОКОГУ  4210014
                ОКПО  93449595
                ОКВЭД (основной) 47.76.2
            </p>
        </div>
    )
}

export default Content;